import { useAccountApi } from '~/api/account'
import { UPDATE_PROFILE } from '~/constants/toast'
import { useAppStore } from '~/store/app'
import { VERIFY_EMAIL } from '~/constants/account/profile/profile'
import { useLoading } from '~/composables/useLoading'
import { MODAL } from '~/constants/modal'

export const useVerifyEmail = () => {
  const { VERIFY_EMAIL_API } = useAccountApi()
  const { $axios, $alertMsg } = useNuxtApp()
  const { isLoading, loading, load } = useLoading()
  const { VERIFY_EMAIL_TOKEN } = useAccountApi()
  const { currentUser } = useAppStore()
  const isVerifyingEmail = ref(false)
  const route = useRoute()
  const textVerify = ref('')
  const iconVerify = ref('')
  const isError = ref(true)
  const { openModalOne } = useModal()
  const checkVerifyEmail = async () => {
    loading()
    try {
      const token = route.query.token
      const { data: response } = await $axios.get(VERIFY_EMAIL_TOKEN, {
        params: {
          token
        }
      })
      if (response && response.status === 'OK') {
        textVerify.value = VERIFY_EMAIL.TEXT_SUCCESS
        iconVerify.value = VERIFY_EMAIL.ICON_SECCESS
        currentUser.is_verify_email = true
        isError.value = false
      } else {
        textVerify.value = VERIFY_EMAIL.TEXT_FAIL
        iconVerify.value = VERIFY_EMAIL.ICON_FAIL
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        textVerify.value = VERIFY_EMAIL.TEXT_EXPIRED
        iconVerify.value = VERIFY_EMAIL.ICON_EXPIRED
      } else {
        textVerify.value = error.response.statusText
        iconVerify.value = VERIFY_EMAIL.ICON_FAIL
      }
    } finally {
      load()
      setTimeout(() => {
        openModalOne(MODAL.VERIFY_EMAIL)
      }, 200)
    }
  }

  const submitVerifyEmail = async (formData: any) => {
    loading()
    try {
      const { data: response } = await $axios.get(VERIFY_EMAIL_API, {
        params: {
          email: formData?.email
        }
      })
      if (response && response.status === 'OK') {
        isVerifyingEmail.value = true
        $alertMsg('success', response.message || UPDATE_PROFILE.CHECK_EMAIL)
        return
      } else {
        $alertMsg('error', response?.message || UPDATE_PROFILE.ERROR_EMAIL)
      }
      load()
    } catch (error) {
      load()
    }
  }

  return {
    isLoading,
    submitVerifyEmail,
    isVerifyingEmail,
    textVerify,
    iconVerify,
    isError,
    checkVerifyEmail
  }
}
